import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const links = [
  { 
    link: "https://www.instagram.com/saintesband/",
    class: "fa-instagram"
  },
  { 
    link: "https://open.spotify.com/artist/1ctrVhBKWrWmg165qzoIHW?si=E2q0KjyuTnOCNuLoTWeYeA",
    class: "fa-spotify"
  },
  { 
    link: "https://www.youtube.com/watch?v=P6vEfu4Mt3I",
    class: "fa-youtube"
  },
  { 
    link: "https://www.facebook.com/SaintesOfficial",
    class: "fa-facebook"
  },
  { 
    link: "mailto:saintesband@gmail.com",
    class: "fa-envelope"
  },
]

function Music(page) {
  console.log(page);
  return page?.page === "header" ? (
    <div className='header-icon-container col-xs-12 d-flex justify-content-center'>
      {links.map((link) => {
        return (
          <a href={link.link} target="_blank" rel="noreferrer" className="social-icon-container">
            <FontAwesomeIcon icon={`fab ${link.class}`} size="xs" className="color-primary social-icon mx-2 dark" />
          </a>
        );
      })}
    </div>
  ) : (
    <Row className='mx-2 mx-sm-0 px-0'>
      {links.map((link) => {
        return (
          <Col xs={3} className="d-flex justify-content-center p-0">

          <a href={link.link} target="_blank" rel="noreferrer" className="social-icon-container">
            <FontAwesomeIcon icon={`fab ${link.class}`} size="sm" className="color-primary social-icon mx-2 light" />
          </a>
          </Col>
        );
      })}
    </Row>
  );
}

export default Music;
