import React from 'react';
import Header from '../Components/Header';
// var __html = require('../Components/mailchimp.html');
// var template = {__html: __html};

function Home() {
  document.title = 'SAINTES - Contact';

  return (
    <div className="App">
      <Header />

      {/* <div dangerouslySetInner={template} /> */}
      
    </div>
  );
}

export default Home;
