// import enter from '../../Assets/ENTER.png';
import React from 'react';
import background from '../assets/images/background-new.jpg';
import { Link } from "react-router-dom";
import SocialIcons from '../Components/SocialIcons';

const links = [
  // { title: 'Uncharted Waters', url: 'https://youtu.be/PIzJ7NSbTN0' },
  // { title: 'Watch Live Session', url: 'https://www.youtube.com/watch?v=j_NPDO_6mjw&t=7s' },
  { title: 'Fair is Fair // Colour of Red', url: 'https://distrokid.com/hyperfollow/saintes/fair-is-fair' },
  
  { title: 'O.T.G.I.A - Listen', url: 'https://open.spotify.com/album/0FvfQaWSJXzpwQke287OjB?si=W-66Lx4USmanDzy_RVWdzA' },
  { title: 'O.T.G.I.A - Buy', url: 'https://saintesband.bandcamp.com/' },
  // { title: 'Album Launch Tickets - 11/06', url: 'https://www.eventbrite.co.uk/e/saintes-otgia-album-release-show-tickets-300897581767' },
  // { title: 'Presave the Album', url: 'https://snd.click/o3sq' },
  // { title: 'Preorder - O.T.G.I.A', url: 'https://saintesband.bandcamp.com/album/o-t-g-i-a' },
  // { title: 'The Trap - Live Session', url: 'https://www.youtube.com/watch?v=j_NPDO_6mjw&t=7s' },
  // { title: 'Merch', url: 'https://saintesband.bandcamp.com/' },
  // { title: 'Contact', url: 'mailto:saintesband@gmail.com' },
  // { title: 'Mailing List', url: 'https://mailchi.mp/fd3076811ad5/saintes' },
  // { title: 'Press', url: '/EPK', internal: true},
];

function Enter() {
  return (
    <div className="App">
      <header className="App-header" style={{ backgroundImage: `url(${background})` }}>
        <ul className="p-2 p-sm-0">
          <h1 class="d-none">SAINTES - Home</h1>
            <SocialIcons page="landing"/>
       
          {links.map((link) => (
            <li className="circle-link my-3">
              {link.internal 
                ? <Link to={link.url} target="_blank" rel="noreferrer">{link.title}</Link>
                : <a href={link.url} target="_blank" rel="noreferrer">{link.title}</a>}
            </li>
          ))}
        </ul>
      </header>
    </div>
  );
}

export default Enter;
