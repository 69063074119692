import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import DocumentMeta from 'react-document-meta';

const meta = {
      title: 'SAINTES',
      description: 'SAINTES - Gritty glam rock band from Sheffield, UK',
      canonical: 'http://saintes.co.uk/',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'react,meta,document,html,tags'
        }
      }
    };

ReactDOM.render(
  <BrowserRouter>
    <DocumentMeta {...meta}>
      <App />
    </DocumentMeta>
  </BrowserRouter>,
  document.getElementById("root")
);