import React from 'react';
import { Routes, Route } from "react-router-dom";
import "./Styles/app.scss";
import Music from './Pages/Music';
import Enter from './Pages/Enter';
import Gallery from './Pages/Gallery';
import Videos from './Pages/Videos';
import EPK from './Pages/Epk';
import Contact from './Pages/Contact';
import Home from './Pages/Home';
import Landing from './Pages/Landing';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab);


function App() {

  return (
    <div className="page">
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="about" element={<About />} /> */}

        {/* <Route  path="/music" element={<Music />} /> */}
        {/* <Route  path="/gallery" element={<Gallery />} /> */}
        {/* <Route exact path="/videos" element={<Videos />} /> */}
        {/* <Route exact path="/home" element={<Enter />} /> */}
        {/* <Route exact path="/contact" element={<Contact />} /> */}
        {/* <Route exact path="/EPK" element={<EPK />} /> */}
        {/* <Route exact path="/links" element={<Enter />} /> */}
        {/* <Route exact path="*" element={<Home />} /> */}
        <Route exact path="*" element={<Landing />} />
      </Routes>
    </div>
  );
}

export default App;